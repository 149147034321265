// * React components
import React, { Suspense, lazy } from 'react';
// * Third party components
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import Contactus from './Components/V1/InnerPages/ContactUs/Contactus';
// import SimsolaLoader from './Components/V1/Loader/SimsolaLoader';

// * Pages;

// const MainPage = lazy(() => import('./Components/V1/InnerPages/ContactUs/Contactus'));
const ResultPage = lazy(() => import('./Components/V2/InnerPages/Result/Result'));
const AboutUs = lazy(() => import('./Components/V2/InnerPages/AboutUs/AboutUs'));
const MainPage = lazy(() => import('./Components/V2/HomePage/MainPage'));
const VisionPage = lazy(() => import('./Components/V2/Vision/Vision'));
const ContactUsPage = lazy(() => import('./Components/V2/InnerPages/ContactUs/ContactUs'));
const ProductPage = lazy(()=> import('./Components/V2//Product/Product'))
const DemoPage = lazy(()=> import('./Components/V2/BookADemo/BookDemo'))
export const AppRoutes = () => {
	return (
		<>
			<BrowserRouter>
				<Suspense fallback={<div>Loading...</div>}>
					<Routes>
						<Route path="/" element={<MainPage />} />
						<Route path="/about-us" element={<AboutUs />} />
						<Route path="/results" element={<ResultPage />} />
						<Route path="/vision" element={<VisionPage />} />
						<Route path="/contact-us" element={<ContactUsPage />} />
						<Route path="/product" element={<ProductPage />} />
						<Route path="/book-a-demo" element={<DemoPage />} />
					</Routes>
				</Suspense>
			</BrowserRouter>
		</>
	);
};

import React from 'react';
import { AppRoutes } from './AppRoutes';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import './Assets/Css/fonts.css';
import './Assets/Css/common.css';
import './Assets/Css/v2-common.css'
const App = () => {
	return (
		<div className="App">
			<AppRoutes />
		</div>
	);
};

export default App;
